import { defineComponent, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';
import { CustomEventName } from '@/assets/ts/enums/custom-event-name.enum';
export default defineComponent({
    name: 'App',
    setup() {
        const router = useRouter();
        onMounted(() => {
            document.addEventListener(CustomEventName.Unauthorized, handleUnauthorizedRequest);
        });
        onUnmounted(() => {
            document.removeEventListener(CustomEventName.Unauthorized, handleUnauthorizedRequest);
        });
        const handleUnauthorizedRequest = () => {
            router.push({ path: '/sm-panel/dashboard' });
        };
        return {};
    }
});
