import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import(/* webpackChunkName: "home" */ './components/home/Home.vue'),
        meta: {
            title: 'Orchidea ŚP',
        },
    },
    {
        path: '/lottery',
        name: 'Lottery',
        component: () => import(/* webpackChunkName: "lottery" */ './components/lottery/Lottery.vue'),
        meta: {
            title: 'Cykliczna loteria',
        },
    },
    {
        path: '/achievements',
        name: 'Achievements',
        component: () => import(/* webpackChunkName: "achievements" */ './components/achievements/Achievements.vue'),
        meta: {
            title: 'Osiągnięcia',
        },
    },
    {
        path: '/sm-panel',
        name: 'AdminPanel',
        component: () => import(/* webpackChunkName: "adminPanelHome" */ './components/panel/AdminPanelHome.vue'),
        meta: {
            title: 'Panel Administratora',
        },
        children: [
            {
                path: 'achievements/management',
                component: () => import(/* webpackChunkName: "adminPanelAchievementManagement" */ './components/panel/panel-achievement-management/AdminPanelAchievementManagement.vue'),
            },
            {
                path: 'achievements/assignment',
                component: () => import(/* webpackChunkName: "adminPanelAchievement" */ './components/panel/panel-achievement-list/AdminPanelAchievement.vue'),
            },
            {
                path: '',
                alias: 'dashboard',
                component: () => import(/* webpackChunkName: "adminPanelDashboard" */ './components/panel/panel-dashboard/AdminPanelDashboard.vue'),
            },
            {
                path: 'nicknames',
                component: () => import(/* webpackChunkName: "adminPanelNicknames" */ './components/panel/panel-nicknames/AdminPanelNicknames.vue'),
            },
            {
                path: 'lottery/management',
                component: () => import(/* webpackChunkName: "adminPanelLotteryManagement" */ './components/panel/panel-lottery/AdminPanelLotteryManagement.vue'),
            },
            {
                path: 'lottery-tickets',
                component: () => import(/* webpackChunkName: "adminPanelLotteryTickets" */ './components/panel/panel-tickets/AdminPanelLotteryTickets.vue'),
            }
        ],
    },
    {
        path: '/nicknames',
        name: 'Nicknames',
        component: () => import(/* webpackChunkName: "nicknames" */ './components/nicknames/NicknameHome.vue'),
        meta: {
            title: 'Baza graczy',
        },
    },
];
const DEFAULT_ROUTER_BASE_URL = '/';
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL || DEFAULT_ROUTER_BASE_URL),
    routes,
});
export default router;
